import Fade from "react-reveal/Fade";
import theme from "@styles/theme";
import { Choose, If } from "react-extras";
import Button from "@common/buttons/Button";

function CleanAlwaysMeansCleanNewBanner() {
  return (
    <div
      css={`
        background-image: radial-gradient(circle at 30% top, #cdd5f6, #cdd5f6 7%, #9492cf);
        padding: 50px 20px 40px;
        text-align: center;
        @media (max-width: ${theme.breakPoints.sm}) {
          padding: 40px 20px 30px;
        }
      `}
    >
      <h3
        css={`
          color: white;
          font-family: ${theme.fonts.headline};
          margin: 0px;
          font-size: 5.2rem;
          @media (max-width: 1500px) {
            font-size: 4.55rem;
          }
          @media (max-width: ${theme.breakPoints.lg}) {
            font-size: 3.75rem;
          }
          @media (max-width: ${theme.breakPoints.sm}) {
            font-size: 3.25rem;
          }
        `}
      >
        CLEAN ALWAYS MEANS CLEAN
      </h3>
      <p
        css={`
          color: white;
          font-size: 2.3rem;
          font-family: ${theme.fonts.paragraph};
          font-weight: 700;
          margin: 0px;
          width: fit-content;
          margin: auto;
          position: relative;
          @media (max-width: ${theme.breakPoints.lg}) {
            font-size: 2.1rem;
            margin-top: 5px;
          }
          @media (max-width: ${theme.breakPoints.sm}) {
            font-size: 1.9rem;
          }
        `}
      >
        <img
          src="/images/icons/dings/white/sparkle-left.svg"
          alt="Ding"
          css={`
            width: 30px;
            position: absolute;
            left: -40px;
            top: 0px;
            @media (max-width: ${theme.breakPoints.sm}) {
              display: none;
            }
          `}
        />
        A SPARKLING HOME. CLEANER WORLD. A SPOTLESS SOUL.
      </p>
      <Fade ssrReveal={true} delay={0} distance={"1.2rem"} bottom>
        <h3
          css={`
            color: ${theme.colors.primary};
            font-size: 4rem;
            font-family: ${theme.fonts.headline};
            margin: 0px;
            margin-top: 30px;
            @media (max-width: ${theme.breakPoints.lg}) {
              font-size: 2.75rem;
              margin-top: 20px;
            }
            @media (max-width: ${theme.breakPoints.sm}) {
              font-size: 2.25rem;
            }
          `}
        >
          #JOINTHECLEANCULT
        </h3>
      </Fade>
    </div>
  );
}

export default CleanAlwaysMeansCleanNewBanner;
