import styled from "styled-components";
import Fade from "react-reveal/Fade";

const Wrapper = styled.div`
  padding: 40px 0px;
`;

const TitleCallout = styled.div`
  padding: 0px 20px 10px;
  background: ${(props) => props.theme.colors.white};

  & h3 {
    color: ${(props) => props.theme.colors.primary};
    font-size: 5rem;
    margin: 0px;
    font-weight: 700;
    text-align: center;
    font-family: ${(props) => props.theme.fonts.headline};
    position: relative;
    width: fit-content;
    margin: 0 auto;
  }
  @media (max-width: 1500px) {
    & h3 {
      font-size: 4.55rem;
    }
  }
  @media (max-width: ${(props) => props.theme.breakPoints.lg}) {
    & h3 {
      font-size: 3.75rem;
    }
  }
  @media (max-width: ${(props) => props.theme.breakPoints.sm}) {
    max-width: 400px;
    margin: auto;
    & h3 {
      font-size: 3.25rem;
    }
  }
`;

const IconRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 900px;
  margin: 0 auto;
  flex-wrap: wrap;
  gap: 15px;
  padding: 0px 40px;
  & img {
    width: 125px;
  }
  @media (max-width: 1500px) {
    & img {
      width: 115px;
    }
  }
  @media (max-width: 1200px) {
    max-width: 800px;
    & img {
      width: 110px;
    }
  }
  @media (max-width: 900px) {
    & img {
      width: 100px;
    }
  }
  @media (max-width: ${(props) => props.theme.breakPoints.sm}) {
    justify-content: center;
    gap: 20px;
    max-width: 400px;
    & img {
      width: 90px;
    }
  }
`;

const baseDelay = 200;
const baseDelayAddition = 150;
const baseDistance = "1.7em";

function SomeCultsAreToxicCallout() {
  return (
    <Wrapper>
      <TitleCallout>
        <Fade ssrReveal={true} distance={"0.5em"} bottom>
          <h3>SOME CULTS ARE TOXIC. NOT US.</h3>
        </Fade>
      </TitleCallout>

      <IconRow>
        <Fade ssrReveal={true} delay={baseDelay} distance={baseDistance} bottom>
          <img src="https://images.ctfassets.net/1s5kub6totmj/308WK80QcshuuFz7NyOp8I/fc0ddb8b0248a1f85fa61efeb1594520/Asset_137.png?h=250" />
        </Fade>
        <Fade ssrReveal={true} delay={baseDelay + baseDelayAddition} distance={baseDistance} bottom>
          <img src="https://images.ctfassets.net/1s5kub6totmj/7G8GLbYmYoQcwWCrvlc0so/f322a1f215deab801b7b778ae61098ae/Asset_140_3x.png?h=250" />
        </Fade>
        <Fade ssrReveal={true} delay={baseDelay + baseDelayAddition * 2} distance={baseDistance} bottom>
          <img src="https://images.ctfassets.net/1s5kub6totmj/4IsmsoL2CorJQ8SIes9eV4/8323e6c019329e751ab6a169cbfc8854/Asset_141_3x.png?h=250" />
        </Fade>
        <Fade ssrReveal={true} delay={baseDelay + baseDelayAddition * 3} distance={baseDistance} bottom>
          <img src="https://images.ctfassets.net/1s5kub6totmj/6ZORfLObqGHbJxXXDNAa1B/dee48b7531c1f445bbb1b9f59c0c5247/Asset_139.png?h=250" />
        </Fade>
        <Fade ssrReveal={true} delay={baseDelay + baseDelayAddition * 4} distance={baseDistance} bottom>
          <img src="https://images.ctfassets.net/1s5kub6totmj/38hPNQnRWq2Y1EFMjmSc5b/647a235155cd65708ad77bf6fc90e7b1/Asset_138.png?h=250" />
        </Fade>
        <Fade ssrReveal={true} delay={baseDelay + baseDelayAddition * 5} distance={baseDistance} bottom>
          <img src="https://images.ctfassets.net/1s5kub6totmj/6mUM65DW9qIGCNG7MK2GUT/60acd4265e71e1b477a37a2411d49abf/Asset_139_3x.png?h=250" />
        </Fade>
      </IconRow>
    </Wrapper>
  );
}

export default SomeCultsAreToxicCallout;
