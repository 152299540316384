import Fade from "react-reveal/Fade";
import Marquee from "react-fast-marquee";

export default function InstagramReel() {
  return (
    <div
      css={`
        background-image: url("https://images.ctfassets.net/1s5kub6totmj/6F3DmcR6MnjLNY88uFnDCF/e8c4ed5bd814d7707fc8e67a619d58ef/Asset_108_2x.png");
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        padding: 35px 0px;
        margin-top: -1px;
        & img {
          border-radius: 10px;
          margin: 0px 30px;
          max-width: 340px;
        }
        @media (max-width: 1250px) {
          padding: 40px 00px 20px;
        }
        @media (max-width: 700px) {
          & img {
            max-width: 250px !important;
            margin: 0px 20px;
          }
        }
      `}
    >
      <div
        css={`
          max-width: 1000px;
          margin: auto;
          position: relative;
          z-index: 9999;
          & img {
            position: absolute;
            right: 0px;
            top: -100px;
            width: 200px;
          }
          @media (max-width: 1100px) {
            & img {
              width: 170px;
              top: -70px;
            }
          }
          @media (max-width: 700px) {
            & img {
              width: 150px;
              top: -70px;
            }
          }
        `}
      >
        <img
          src="https://images.ctfassets.net/1s5kub6totmj/4eryf1S2MXa1mHqs2JUYaG/32940811b0480a39b40d43fe884fec8e/Asset_100_2x.png?h=250"
          alt="badges"
        />
      </div>
      <Marquee gradient={false} speed={50} loop={0}>
        <Fade ssrReveal={true} delay={0}>
          <img
            src="https://images.ctfassets.net/1s5kub6totmj/26HjyQdzhBNdGadrgckBBv/32c91b56846192c64ec6a6df3c9b7d35/7.jpg?h=250"
            alt="Instagram Reel"
          />
        </Fade>
        <Fade ssrReveal={true} delay={0}>
          <img
            src="https://images.ctfassets.net/1s5kub6totmj/42Vi1eS3XwRupz2JfAP3Hs/d10e280967d00a9dc140d79dad5fdd48/4.jpg?h=250"
            alt="Instagram Reel"
          />
        </Fade>
        <Fade ssrReveal={true} delay={0}>
          <img
            src="https://images.ctfassets.net/1s5kub6totmj/1zUKYSOK7AKnBmgLHV0x3g/5e190959c9d11776c0c059edfd23e2a8/3.jpg?h=250"
            alt="Instagram Reel"
          />
        </Fade>
        <Fade ssrReveal={true} delay={0}>
          <img
            src="https://images.ctfassets.net/1s5kub6totmj/xmGDwSkdVf8jTagh9YQy8/a921f5f68ffc61d1f512f107afd1523e/2.jpg?h=250"
            alt="Instagram Reel"
          />
        </Fade>
        <Fade ssrReveal={true} delay={0}>
          <img
            src="https://images.ctfassets.net/1s5kub6totmj/5JU29Q2VMg8AZdbiGfCHDd/8af502eb587b38e9506dc3df31bbd936/5.jpg?h=250"
            alt="Instagram Reel"
          />
        </Fade>
        <Fade ssrReveal={true} delay={0}>
          <img
            src="https://images.ctfassets.net/1s5kub6totmj/5aEF9y8nu12NPNyq7aeEJC/854d227b21eaaaa7213d92748d306ea9/6.jpg?h=250"
            alt="Instagram Reel"
          />
        </Fade>
        <Fade ssrReveal={true} delay={0}>
          <img
            src="https://images.ctfassets.net/1s5kub6totmj/117G4FBZcyyCRtcWOq2EJh/41f4ee321b4535ba786d016e525664cb/1.jpg?h=250"
            alt="Instagram Reel"
          />
        </Fade>
      </Marquee>
    </div>
  );
}
