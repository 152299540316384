import Fade from "react-reveal/Fade";
import theme from "@styles/theme";
import { Choose, If } from "react-extras";
import Button from "@common/buttons/Button";

export default function EasyRefillSystemNew() {
  const titlePoint = (title) => {
    return (
      <div
        css={`
          padding: 15px 0px;
          display: flex;
          flex-direction: row;
          align-items: flex-start;
          @media (max-width: 1500px) {
            padding: 10px 0px;
          }
          @media (max-width: 800px) {
            padding: 7px 0px;
          }
          @media (max-width: ${theme.breakPoints.sm}) {
          }
        `}
      >
        <img
          src="/images/icons/dings/blue/ding.svg"
          alt="burst"
          width={25}
          css={`
            margin-top: -4px;
          `}
        />
        <p
          css={`
            margin: 0px 10px;
            color: ${theme.colors.primary};
            font-size: 2.1rem;
            font-weight: 500;

            @media (max-width: 1500px) {
              font-size: 1.9rem;
              margin: 0px 7px;
            }
            @media (max-width: ${theme.breakPoints.lg}) {
              font-size: 1.8rem;
              margin: 0px 3px;
            }
          `}
        >
          {title}
        </p>
      </div>
    );
  };

  return (
    <div
      css={`
        padding: 60px 20px;
        max-width: 980px;
        margin: auto;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: space-between;
        gap: 40px;
        @media (max-width: 1500px) {
          max-width: 900px;
        }
        @media (max-width: 1300px) {
          max-width: 850px;
        }
        @media (max-width: 800px) {
          max-width: 900px;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          padding: 40px 20px;
        }
        @media (max-width: ${theme.breakPoints.sm}) {
          max-width: 900px;
        }
      `}
    >
      <Fade ssrReveal delay={0} fraction={0.05} bottom distance="1.2rem">
        <div
          css={`
            position: relative;
          `}
        >
          <img
            src="https://images.ctfassets.net/1s5kub6totmj/3ukUAkgMPqnM5lJvjwMSic/3b32f29a519acadcf5b54d795005c153/90.png?h=250"
            alt="90% Less Plastic Badge"
            css={`
              max-width: 120px;
              position: absolute;
              left: -40px;
              @media (max-width: 1500px) {
                max-width: 120px;
              }
              @media (max-width: ${theme.breakPoints.lg}) {
                max-width: 115px;
                left: 0px;
              }
              @media (max-width: ${theme.breakPoints.sm}) {
                max-width: 100px;
              }
            `}
          />
          <img
            src="https://images.ctfassets.net/1s5kub6totmj/2XS0msGeG9Z6WhMtjKOzF4/a280e2f248d255a0712b98fa39150722/Asset_144_3x.png?h=550"
            alt="Easy Refill System"
            css={`
              max-width: 400px;
              @media (max-width: 1500px) {
                max-width: 380px;
              }
              @media (max-width: ${theme.breakPoints.lg}) {
                max-width: 350px;
              }
              @media (max-width: ${theme.breakPoints.sm}) {
                max-width: 320px;
                width: 100%;
              }
            `}
          />
        </div>
      </Fade>
      <div
        css={`
          display: flex;
          flex-direction: column;
          justify-content: center;
        `}
      >
        <Fade ssrReveal delay={0} fraction={0.05} bottom distance="1.2rem">
          <h3
            css={`
              color: ${theme.colors.primary};
              font-size: 4.5rem;
              font-family: ${theme.fonts.headline};
              margin: 0px;
              @media (max-width: 1500px) {
                font-size: 4.05rem;
              }
              @media (max-width: ${theme.breakPoints.lg}) {
                font-size: 3.25rem;
              }
              @media (max-width: ${theme.breakPoints.sm}) {
                font-size: 2.75rem;
                text-align: center;
              }
            `}
          >
            EASY REFILL SYSTEM
          </h3>
        </Fade>
        <div
          css={`
            padding: 20px 5px;
            @media (max-width: ${theme.breakPoints.sm}) {
              padding: 10px 5px;
            }
          `}
        >
          <Fade ssrReveal delay={250} fraction={0.05} bottom distance="1.2rem">
            {titlePoint("Recyclable paper-based refills + forever bottles")}
          </Fade>
          <Fade ssrReveal delay={500} fraction={0.05} bottom distance="1.2rem">
            {titlePoint("Free from the harsh stuff")}
          </Fade>
          <Fade ssrReveal delay={750} fraction={0.05} bottom distance="1.2rem">
            {titlePoint("Heavenly scents for a cleaner state of mind")}
          </Fade>
        </div>
        <Fade ssrReveal delay={1000} fraction={0.05} bottom distance="1.2rem">
          <div
            css={`
              @media (max-width: ${theme.breakPoints.sm}) {
                text-align: center;
              }
            `}
          >
            <Button link href="/refill-ritual" modifiers={["primary", "medium"]}>
              LEARN MORE
            </Button>
          </div>
        </Fade>
      </div>
    </div>
  );
}
