import React from "react";
import HomePageHeroNew from "@components/pages/home/HomePageHeroNew";
import SomeCultsAreToxicCallout from "@components/pages/home/SomeCultsAreToxicCallout";
import CloudContent from "@components/pages/home/CloudContent";
import CleanAlwaysMeansCleanNewBanner from "@components/pages/home/CleanAlwaysMeansCleanNewBanner";
import InstagramReel from "@components/pages/home/InstagramReel";
import EasyRefillSystemNew from "@components/pages/home/EasyRefillSystemNew";

function Index() {
  return (
    <>
      <HomePageHeroNew />
      <SomeCultsAreToxicCallout />
      <CloudContent />
      <EasyRefillSystemNew />
      <CleanAlwaysMeansCleanNewBanner />
      <InstagramReel />
    </>
  );
}

export default Index;
