import Fade from "react-reveal/Fade";
import theme from "@styles/theme";
import { Choose, If } from "react-extras";
import Button from "@common/buttons/Button";

function CleanerStateOfMind({ title, description, image, ifHasIcon }) {
  return (
    <div
      css={`
        position: relative;
      `}
    >
      <div>
        <div
          css={`
            position: relative;

            & img {
              width: 230px;
            }
          `}
        >
          <If condition={ifHasIcon}>
            <img
              src={
                "https://images.ctfassets.net/1s5kub6totmj/3qBCKV59oTeObmcYqDTzLF/46b7d776af19e10096901c6c20bc4d98/Asset_143.png?h=250"
              }
              alt={"Good House Keeping Badge"}
              css={`
                position: absolute;
                width: 110px !important;
                right: -25px;
                top: 25px;
              `}
            />
          </If>
          <img src={`${image}?w=350`} alt={title} />
        </div>
      </div>
      <div
        css={`
          width: 253px;
          margin-left: -10px;
        `}
      >
        <p
          css={`
            text-align: center;
            color: ${theme.colors.primary};
            font-weight: 700;
            font-size: 2.1rem;
            margin: 0px;
            margin-top: 10px;
          `}
        >
          {title}
        </p>
        <p
          css={`
            text-align: center;
            margin: 0px;
            color: ${theme.colors.primary};
            font-weight: 600;
            font-size: 1.5rem;
            padding: 0px 20px;
            max-width: 1500px;
            margin-top: 4px;
          `}
        >
          {description}
        </p>
      </div>
    </div>
  );
}
const baseDelay = 200;
const baseDelayAddition = 150;
const baseDistance = "1.7em";

function CloudContent() {
  return (
    <div
      css={`
        background-image: url("https://images.ctfassets.net/1s5kub6totmj/6F3DmcR6MnjLNY88uFnDCF/e8c4ed5bd814d7707fc8e67a619d58ef/Asset_108_2x.png");
        background-size: cover;
        background-repeat: no-repeat;
        background-position: top;
        padding: 60px 20px 20px;
        @media (max-width: 1250px) {
          padding: 40px 00px 20px;
        }
      `}
    >
      <div
        css={`
          max-width: 1200px;
          margin: 0 auto;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-wrap: wrap;
          flex-direction: row;
          gap: 100px;
          padding: 0px 20px;
          @media (max-width: ${theme.breakPoints.lg}) {
            gap: 60px;
          }
          @media (max-width: ${theme.breakPoints.sm}) {
            gap: 40px;
          }
        `}
      >
        <Fade ssrReveal delay={0} fraction={0.05} bottom distance="1.2rem" style={{ flexBasis: "50%" }}>
          <div
            css={`
              flex-basis: 50%;
              text-align: center;
            `}
          >
            <img
              src="https://images.ctfassets.net/1s5kub6totmj/ZFIH1PJkiEnj90w47Vwle/f5e2a2e1648e1662bbcc2b4dbd5e6522/Asset_103_4x.png?w=350"
              css={`
                width: 100%;
                max-width: 350px;
                margin: 0 auto;
                @media (max-width: 1500px) {
                  max-width: 335px;
                }
                @media (max-width: ${theme.breakPoints.lg}) {
                  max-width: 330px;
                }
                @media (max-width: ${theme.breakPoints.sm}) {
                  max-width: 300px;
                }
                @media (max-width: ${theme.breakPoints.xs}) {
                  max-width: 270px;
                }
              `}
              alt="Be a no-plastic fanatic"
            />
            <p
              css={`
                font-size: 2.3rem;
                color: ${theme.colors.primary};
                font: ${theme.fonts.paragraph};
                font-weight: 600;
                line-height: 1.5;
                max-width: 400px;
                margin: 0 auto;
                margin-top: 20px;
                @media (max-width: ${theme.breakPoints.lg}) {
                  font-size: 2.1rem;
                }
                @media (max-width: ${theme.breakPoints.xs}) {
                  font-size: 1.9rem;
                }
              `}
            >
              At Cleancult, we believe clean should mean clean in every single way. That we should refill not landfill,
              that paper is mightier than plastic, and that lots of small actions add up to great change. We're on a
              mission to help more people switch from a bad plastic habit to a cleaner refill ritual.
            </p>
          </div>
        </Fade>
        <Fade ssrReveal delay={200} fraction={0.05} bottom distance="1.2rem">
          <div
            css={`
              flex-basis: 50%;
            `}
          >
            <div
              css={`
                width: 450px;
                @media (max-width: ${theme.breakPoints.lg}) {
                  width: 380px;
                }
                @media (max-width: ${theme.breakPoints.sm}) {
                  width: 300px;
                }
              `}
            >
              <iframe
                css={`
                  border: none;
                  outline: none;
                  @media (max-width: ${theme.breakPoints.lg}) {
                    height: 500px;
                  }
                  @media (max-width: ${theme.breakPoints.sm}) {
                    height: 400px;
                  }
                `}
                width="100%"
                height="570px"
                src="https://www.youtube.com/embed/-atqWn_j_fw?si=XtQXxgleWNOD4g0m?controls=0"
                title="YouTube video player"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen
              ></iframe>
            </div>
          </div>
        </Fade>
      </div>
      <div
        css={`
          max-width: 1200px;
          margin: 60px auto 40px;
          gap: 100px;
          @media (max-width: ${theme.breakPoints.lg}) {
            gap: 60px;
          }
          @media (max-width: ${theme.breakPoints.sm}) {
            gap: 40px;
          }
        `}
      >
        <Fade ssrReveal={true} delay={0} distance={baseDistance} bottom>
          <h3
            css={`
              font-family: ${theme.fonts.headline};
              color: ${theme.colors.primary};
              font-size: 5rem;
              text-align: center;
              margin: 40px 0px 30px 0px;
              padding: 0px 20px;
              @media (max-width: 1500px) {
                & h3 {
                  font-size: 4.55rem;
                }
              }
              @media (max-width: ${theme.breakPoints.lg}) {
                font-size: 3.75rem;
              }
              @media (max-width: ${theme.breakPoints.sm}) {
                font-size: 3.25rem;
                margin: 20px 0px 20px 0px;
              }
            `}
          >
            FIND A CLEANER STATE OF MIND
          </h3>
        </Fade>

        <div
          css={`
            display: flex;
            justify-content: center;
            gap: 80px;
            @media (max-width: 1250px) {
              gap: 40px;
            }
            @media (max-width: 1150px) {
              gap: 20px;
            }
            @media (max-width: 1090px) {
              justify-content: start; // Changed from center to start.
              overflow-x: auto; // Changed from scroll to auto.
              overflow-y: hidden; // Hide vertical  overflow.

              padding: 0px 20px 15px;
            }
            @media (max-width: ${theme.breakPoints.sm}) {
              gap: 50px;
            }
          `}
        >
          <Fade ssrReveal={true} delay={baseDelay} distance={baseDistance} bottom>
            <CleanerStateOfMind
              title="Born Again and Again"
              description="Refill for eternity"
              image="https://images.ctfassets.net/1s5kub6totmj/1XJHzy1TQuMwJExSqwbM0d/c5234ef491fc41dd36bad3a47dd35226/arch_1.png"
              ifHasIcon={false}
            />
          </Fade>
          <Fade ssrReveal={true} delay={baseDelay + baseDelayAddition} distance={baseDistance} bottom>
            <CleanerStateOfMind
              title="A Cleaner Kind Of Cult"
              description="None of the harsh stuff"
              image="https://images.ctfassets.net/1s5kub6totmj/UIKQBBhD7W1EwRB2TCLAf/4bbd815aa5767efa2c661d77932a514f/arch_2.png"
              ifHasIcon={false}
            />
          </Fade>
          <Fade ssrReveal={true} delay={baseDelay + baseDelayAddition * 2} distance={baseDistance} bottom>
            <CleanerStateOfMind
              title="Scent From The Heavens"
              description="Smells like Clean Spirit"
              image="https://images.ctfassets.net/1s5kub6totmj/4PC0pjUwmzoaDiIGsysrX3/9110d6df311fd936b7786f215a69d318/arch_2.png"
              ifHasIcon={true}
            />
          </Fade>
          <Fade ssrReveal={true} delay={baseDelay + baseDelayAddition * 3} distance={baseDistance} bottom>
            <CleanerStateOfMind
              title="Worship Your Surfaces"
              description="Conquers dirt, trashes plastic"
              image="https://images.ctfassets.net/1s5kub6totmj/5c0rvql26PWsbEVtVIUqyg/8148d6d7c2a16922c95a85ebf205dc2a/arch_4.png"
              ifHasIcon={false}
            />
          </Fade>
        </div>
        <div
          css={`
            margin-top: 40px;
            text-align: center;
          `}
        >
          <Button href="/products/shop/all" modifiers={["primary"]} link>
            Shop All Products
          </Button>
        </div>
      </div>
    </div>
  );
}

export default CloudContent;
